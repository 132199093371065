import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primaryDark: '#003D9B',
                primary: '#306CFB',
                primaryLight: '#0052d1',
                primaryLight2: '#2b76ed',
                secondary: '#29CFFD',
                accent: '#FD7369',
                accentLight: '#ff9e96',
                accentDark: '#a83128',
                accent2: '#E2D9AF',
                accent3: '#FFFFFF',
            },
        },
    },
})

export default vuetify