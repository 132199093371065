<template>
    <v-snackbar 
        v-model="globalSnackbarPayload.show" 
        timeout="5000"
        :color="globalSnackbarPayload.color"
        style="bottom: 30px;"
    >
        {{ globalSnackbarPayload.text }}

        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="closeSnackbar" icon>
                <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    computed: {
        globalSnackbarPayload() {
            return this.$store.state.global.globalSnackbar;
        },
    },

    methods: {
        closeSnackbar() {
            this.$store.dispatch('global/triggerGlobalSnackbar', {
                show: false,
            });
        },
    },
}
</script>

<style scoped>

@media screen and (max-width: 280px) {
    .v-snack >>> .v-snack__wrapper {
        max-width: 80vw;
        font-size: 0.8rem;
    }
}

</style>