export default {
    
    SET_TEAM_PROFILE(state, teamProfile) {
        state.teamProfile = teamProfile;
    },

    SET_IS_FINALE(state, isFinale) {
        state.isFinale = isFinale;
    },

}