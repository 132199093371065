import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase/config'
import participant from '@/firebase/participant'
import judge from '@/firebase/judge'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/HomeView.vue'),
    meta: {
      authRequired: false
    },    
  },
  {
    path: '/organisers',
    name: 'Organisers',
    component: () => import('@/views/home/OrganisersList.vue'),
    meta: {
      authRequired: false
    },    
  },
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('@/views/home/PartnersList.vue'),
    meta: {
      authRequired: false
    },    
  },
  {
    path: '/register',
    name: 'RegisterParticipantAccount',
    component: () => import('@/views/auth/RegisterParticipantAccount.vue'),
    meta: {
      authRequired: false,
      preventAuthUser: true
    },
  },
  {
    path: '/login',
    name: 'LoginParticipant',
    component: () => import('@/views/auth/LoginParticipant.vue'),
    meta: {
      authRequired: false,
      preventAuthUser: true
    },
  },
  {
    path: "/create-profile",
    name: "CreateTeamProfile",
    component: () => import('@/views/participant/CreateTeamProfile.vue'),
    meta: {
      authRequired: true
    },
  },
  {
    path: "/dashboard",
    name: "TeamDashboard",
    component: () => import('@/views/participant/TeamDashboard.vue'),
    meta: {
      authRequired: true,
      preventJudgeUser: true
    },
  },
  {
    path: "/judging/login",
    name: "JudgeLogin",
    component: () => import('@/views/auth/LoginJudge.vue'),
    meta: {
      judgeOnly: true,
      preventAuthUser: true
    },
  },
  {
    path: "/judging/verify",
    name: "JudgeVerify",
    component: () => import('@/views/auth/VerifyJudge.vue'),
    meta: {
      judgeOnly: true,
      preventAuthUser: true
    },
  },
  {
    path: "/judging/dashboard",
    name: "JudgeDashboard",
    component: () => import('@/views/judge/JudgeDashboard.vue'),
    meta: {
      authRequired: true,
      judgeOnly: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

router.beforeEach(async (to, from, next) => {

  // let password = prompt();
  // if (password !== 'letmeinpls') {
  //   return
  // }

  // if the route is not found, redirect to the root
  if (to.matched.length === 0) {
    next({ path: '/' })
    return
  } 

  if (to.meta.preventAuthUser || to.meta.authRequired) {
    const user = auth.currentUser

    // if user is logged in and tries to access login or register page, redirect to the root
    if (to.meta.preventAuthUser) {
      if (user) {
        next({ path: '/' })
        return
      }
    }

    // if user is not logged in and tries to access a page that requires authentication, redirect to the root
    if (to.meta.authRequired) {
      await store.dispatch('auth/getUser')

      if (!user) {
        next({ path: '/' })
        return
      }
      
      if (to.meta.judgeOnly) {
        const judgeDetails = await judge.getJudgeIdentity(user.email)
        if (!judgeDetails) {
          next({ path: '/' })
          return
        }
      }

      if (to.meta.preventJudgeUser) {
        const judgeDetails = await judge.getJudgeIdentity(user.email)
        if (judgeDetails) {
          next({ path: '/' })
          return
        }
      }

      if (to.name === 'CreateTeamProfile') {
        const isTeamProfileSubmitted = await participant.isTeamProfileSubmitted()
        if (isTeamProfileSubmitted) {
          next({ path: '/' })
        } else {
          next()
        }
        return
      }

    }
  }
  
  // prevent navigating to the same route
  if (to.name === from.name) {
    next(false)
    return
  }

  else next()

})

export default router
