export default {

    downloadFileFromUrl(url, fileName) {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName; // Specify the desired filename

        // Trigger the download by programmatically clicking on the anchor element
        link.click();
    },

    getEmbedUrl(url) {
        let videoType = 'other';
        let embedVideoUrl = url;

        // Check if it is a youtube video
        const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/)|youtu\.be\/)([^\s&]+)/;
        const youtubeMatch = youtubeRegex.exec(url);
        if (youtubeMatch) {
            const youtubeVideoId = youtubeMatch[1];
            return {
                videoType: 'youtube',
                embedVideoUrl: `https://www.youtube.com/embed/${youtubeVideoId}`,
            };
        }

        // Check if it is a Google Drive video
        const googleDriveRegex = /https:\/\/drive.google.com\/file\/d\/(.*)\/view\?usp=sharing/;
        const googleDriveMatch = googleDriveRegex.exec(url);
        if (googleDriveMatch) {
            const googleDriveVideoId = googleDriveMatch[1];
            return {
                videoType: 'googleDrive',
                embedVideoUrl: `https://drive.google.com/file/d/${googleDriveVideoId}/preview`,
            }
        }

        return {
            videoType,
            embedVideoUrl,
        }
    }
}