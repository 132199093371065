<template>
    <v-app-bar
        class="navbar"
        :class="{ 'navbar-hidden': !showNavbar }"
        elevation="0"
    >
        <v-img
            src="@/assets/brand/logo-circle-light.png"
            :max-width="logoSize"
            @click="toRoot"
            style="cursor: pointer;"
        ></v-img>

        <v-spacer></v-spacer>

        <div v-if="!showMobileNav">
            <a 
                v-for="(nav, i) in hashNavLinks"
                :key="i"    
                :href="nav.path" 
                style="text-decoration: none;"
            >
                <v-btn
                    text
                    color="primaryDark"
                    class="elevation-0 mr-3 py-4"
                    :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                >
                    <span class="text-subtitle-2 font-weight-bold">{{ nav.title }}</span>
                </v-btn>
            </a>

            <v-btn
                v-for="(nav, i) in navLinks"
                :key="i"
                :to="nav.path"
                text
                color="primaryDark"
                class="elevation-0 mr-3 py-4"
                :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
            >
                <span class="text-subtitle-2 font-weight-bold">{{ nav.title }}</span>
            </v-btn>
        </div>

        <div v-if="showLoginSignupButtons">
            <v-btn
                outlined
                color="primaryDark"
                class="elevation-0 mr-3 py-4"
                :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                :to="{ name: 'LoginParticipant' }"
            >
                <span class="text-subtitle-2 font-weight-bold">Login</span>
            </v-btn>
        </div>
        <div v-if="showTeamProfileButton">
            <v-btn
                color="primaryDark"
                class="elevation-0 mr-3 py-4 white--text"
                :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                @click="visitTeamDashboard"
            >
                <v-icon left>mdi-account-circle</v-icon>
                <span class="text-subtitle-2 font-weight-bold">Dashboard</span>
            </v-btn>
        </div>
        <div v-if="showJudgeDashboardButton">
            <v-btn
                dark
                color="primaryDark"
                class="elevation-0 mr-3 py-4 white--text"
                :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                @click="visitJudgeDashboard"
            >
                <v-icon left>mdi-account-circle</v-icon>
                <span class="text-subtitle-2 font-weight-bold">Judge Dashboard</span>
            </v-btn>
        </div>
        <v-menu
            bottom
            left
            offset-y
            nudge-left="20px"
            v-if="showMobileNav"
        >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primaryDark">mdi-menu</v-icon>
              </v-btn>
            </template>

            <v-list dark color="primaryDark" style="min-width: 200px">
                <div
                    v-for="(nav, i) in hashNavLinks"
                    :key="i"
                    class="navItem"
                >
                    <a :href="nav.path" style="text-decoration: none;">
                        <v-list-item>
                            <v-list-item-icon class="mr-5" style="opacity: 0.6">
                                <v-icon>mdi-{{ nav.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                {{ nav.title }}
                            </v-list-item-title>
                        </v-list-item>
                    </a>
                </div>

                <router-link
                    v-for="(nav, i) in navLinks"
                    :key="i"
                    :to="nav.path"
                    style="text-decoration: none;"
                >
                    <v-list-item class="navItem">
                        <v-list-item-icon class="mr-5" style="opacity: 0.6">
                            <v-icon>mdi-{{ nav.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ nav.title }}
                        </v-list-item-title>
                    </v-list-item>
                </router-link>

                <div v-if="showLogoutButton" class="mt-2" style="border-top: 0.5px solid white"></div>
                <v-list-item v-if="showLogoutButton" @click="logout" class="navItem mt-2">
                    <v-list-item-title>
                        Logout
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <div v-if="showLogoutButton && !showMobileNav">
            <v-btn
                dark
                outlined
                color="primaryDark"
                class="elevation-0 mr-3"
                :small="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'"
                @click="logout"
            >
                <v-icon v-if="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm'">mdi-logout</v-icon>
                <span v-else class="text-subtitle-2 font-weight-bold">Logout</span>
            </v-btn>
        </div>
    </v-app-bar>
</template>

<script>

import { auth } from '@/firebase/config'
import participant from '@/firebase/participant'
import judge from '@/firebase/judge'

export default {
    name: 'NavBar',

    data: () => ({
        showNavbar: true,
        lastScrollPosition: 0,
        showVerifyEmailBanner: false,
        showJudgeDashboardButton: false,

        hashNavLinks: [
            { title: 'Timeline', path: '/#timeline', icon: 'calendar-month' },
            { title: 'FAQ', path: '/#faq', icon: 'frequently-asked-questions' },
            { title: 'Contact us', path: '/#contact', icon: 'email' },
            { title: 'Sponsors', path: '/#sponsors', icon: 'web' },
            { title: 'Partners', path: '/partners', icon: 'handshake' },
        ],

        navLinks: [
            // { title: 'Partners', path: '/partners', icon: 'handshake' },
            { title: 'Organisers', path: '/organisers', icon: 'account-group' },
        ],
    }),

    computed: {
        showLoginSignupButtons() {
            const user = auth.currentUser
            return !user
        },

        showLogoutButton() {
            const user = auth.currentUser
            return user
        },

        showTeamProfileButton() {
            if (this.showJudgeDashboardButton) return false
            
            const user = auth.currentUser
            const hasTeam = participant.isTeamProfileSubmitted
            return user && hasTeam
        },

        showMobileNav() {
            return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'md'
        },

        logoSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 30
                case 'sm': return 50
                case 'md': return 50
                case 'lg': return 50
                case 'xl': return 50
                default: return 50
            }
        },

        navButtonSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 100
                case 'sm': return 10
                case 'md': return 150
                case 'lg': return 150
                case 'xl': return 150
                default: return 100
            }
        }
    },

    methods: {
        onScroll () {
            if (window.pageYOffset < 0) {
                return
            }
            if (Math.abs(window.pageYOffset - this.lastScrollPosition) < 80) {
                return
            }

            this.showNavbar = window.pageYOffset < this.lastScrollPosition
            this.lastScrollPosition = window.pageYOffset
        },

        toRoot() {
            if (this.$route.path != '/') {
                this.$router.push("/");
            }
        },

        visitTeamDashboard() {
            this.$router.push({ name: 'TeamDashboard' })
        },

        visitJudgeDashboard() {
            this.$router.push({ name: 'JudgeDashboard' })
        },

        logout() {
            this.$store.dispatch('auth/logoutUser')
        },
    },

    async mounted () {
        this.lastScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        window.addEventListener('scroll', this.onScroll)

        const user = auth.currentUser
        const judgeExists = await judge.getJudgeIdentity(user.email)
        if (user && judgeExists) {
            this.showJudgeDashboardButton = true
        }
    },

    beforeDestroy () {
        window.removeEventListener('scroll', this.onScroll)
    }
}

</script>

<style>

.navbar {
    background: rgba(255, 255, 255, 0.5) !important; 
    position: fixed;
    height: 80px;
    top: 0;
    transition: top 0.3s;
    z-index: 1000;
}

.navbar-hidden {
    top: -80px;
}

.navItem {
    border-radius: 5px;
    margin: 0px 10px;
    cursor: pointer;
}

.navItem:hover {
    background: rgba(255, 255, 255, 0.2);
}

</style>