import { doc, getDoc, setDoc, collection, getDocs, updateDoc } from "firebase/firestore";
import { auth, db } from "@/firebase/config";
import { storage } from "@/firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import gsheet from "@/utils/gsheet";

const selectCaseStudy = async (caseStudy) => {
    try {
        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
    
            if (userDocSnap.data().teamName) {
                const teamName = userDocSnap.data().teamName;
    
                // create new doc in collection caseStudy with teamName as doc id
                const caseStudyDocRef = doc(db, "caseStudy", teamName);
                const caseStudyDocSnap = await getDoc(caseStudyDocRef);
    
                if (caseStudyDocSnap.exists()) {
                    throw new Error("Case study already selected.");
                }
    
                await setDoc(caseStudyDocRef, {
                    caseStudy,
                });
    
                const data = {
                    sheet: "case_study",
                    team_name: teamName,
                    case_study: caseStudy,
                };
    
                await gsheet.sendDataToGoogleSheet(data);
    
                return {
                    message: "Case study selected successfully.",
                };
            } else {
                throw new Error("Team profile not submitted.");
            }
        }
        
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message,
        };
    }
};

const getSelectedCaseStudy = async () => {
    try {
        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
    
            if (userDocSnap.data().teamName) {
                const teamName = userDocSnap.data().teamName;
                const caseStudyDocRef = doc(db, "caseStudy", teamName);
                const caseStudyDocSnap = await getDoc(caseStudyDocRef);
    
                if (caseStudyDocSnap.exists()) {
                    return caseStudyDocSnap.data().caseStudy
                } 
            }
        }

        return false;

    } catch (e) {
        return {
            error: true,
            errorMessage: e.message,
        };
    }
};

const getCaseStudyStatistics = async () => {
    try {
        const caseStudyCounts = {
            environment: 0,
            health: 0,
            education: 0,
            waste: 0,
            transportation: 0
        };

        const querySnapshot = await getDocs(collection(db, "caseStudy"));
        querySnapshot.forEach(async (doc) => {
            const selectedCaseStudy = await doc.data().caseStudy;

            if (selectedCaseStudy in caseStudyCounts) {
                caseStudyCounts[selectedCaseStudy]++;
            }
        });

        return caseStudyCounts;

    } catch (e) {
        return {
            error: true,
            errorMessage: e.message,
        };
    }
};

const submitPreliminaryProject = async (project) => {
    try {
        const selectedCaseStudy = await getSelectedCaseStudy();
        if (!selectedCaseStudy) {
            throw new Error("Case study not selected.");
        }

        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.data().teamName) {
                const teamName = userDocSnap.data().teamName;
    
                const preliminarySubmissionDocRef = doc(db, "preliminarySubmission", teamName);
                const preliminarySubmissionDocSnap = await getDoc(preliminarySubmissionDocRef);
    
                if (preliminarySubmissionDocSnap.exists()) {
                    throw new Error("Project already submitted.");
                }
    
                const slideDeckUrl = await uploadSlideDeck('slideDeck', project.slideDeck, teamName, selectedCaseStudy)
    
                await setDoc(preliminarySubmissionDocRef, {
                    caseStudy: selectedCaseStudy,
                    projectName: project.projectName,
                    solutionType: project.solutionType,
                    oneLiner: project.oneLiner,
                    slideDeck: slideDeckUrl,
                    pitchVideoUrl: project.pitchVideoUrl,
                    prototypeUrl: project.prototypeUrl,
                });
    
                const data = {
                    sheet: "preliminary_submission",
                    team_name: teamName,
                    case_study: selectedCaseStudy,
                    project_name: project.projectName,
                    solution_type: project.solutionType,
                    one_liner: project.oneLiner,
                    slide_deck: slideDeckUrl,
                    pitch_video_url: project.pitchVideoUrl,
                    prototype_url: project.prototypeUrl,
                };
                await gsheet.sendDataToGoogleSheet(data);
    
                return {
                    message: "Project submitted successfully.",
                };
    
            } else {
                throw new Error("Team profile not created yet.");
            }
        }
        
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message,
        };
    }
};

const submitFinaleProject = async (project) => {
    try {
        const selectedCaseStudy = await getSelectedCaseStudy();
        if (!selectedCaseStudy) {
            throw new Error("Case study not selected.");
        }

        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.data().teamName) {
                const teamName = userDocSnap.data().teamName;
    
                const finaleSubmissionDocRef = doc(db, "finaleSubmission", teamName);
                const finaleSubmissionDocSnap = await getDoc(finaleSubmissionDocRef);
    
                if (finaleSubmissionDocSnap.exists()) {
                    throw new Error("Project already submitted.");
                }
    
                const slideDeckUrl = await uploadSlideDeck('finaleSlideDeck', project.slideDeck, teamName, selectedCaseStudy)

                let prototypeDemoScreenshotsUrls = [];
                if (project.prototypeDemoScreenshotsUrls) {
                    for (const screenshot of project.prototypeDemoScreenshotsUrls) {
                        prototypeDemoScreenshotsUrls.push(await uploadFile('finalePrototypeDemoScreenshot', screenshot, teamName, selectedCaseStudy))
                    }
                }
    
                await setDoc(finaleSubmissionDocRef, {
                    caseStudy: selectedCaseStudy,
                    projectName: project.projectName,
                    solutionType: project.solutionType,
                    slideDeck: slideDeckUrl,
                    slideDeckCanvaUrl: project.slideDeckCanvaUrl,
                    prototypeDemoVideoUrl: project.prototypeDemoVideoUrl || "",
                    prototypeDemoScreenshotsUrls: prototypeDemoScreenshotsUrls,
                    prototypeUrl: project.solutionUrl,
                    software: project.software,
                });
    
                const data = {
                    sheet: "finale_submission",
                    team_name: teamName,
                    case_study: selectedCaseStudy,
                    project_name: project.projectName,
                    solution_type: project.solutionType,
                    slide_deck: slideDeckUrl,
                    slide_deck_canva: project.slideDeckCanvaUrl,
                    prototype_demo_video_url: project.prototypeDemoVideoUrl,
                    prototype_demo_screenshots_urls: prototypeDemoScreenshotsUrls.join(","),
                    prototype_url: project.solutionUrl,
                    software: project.software,
                };
                await gsheet.sendDataToGoogleSheet(data);
    
                return {
                    message: "Project submitted successfully.",
                };
    
            } else {
                throw new Error("Team profile not created yet.");
            }
        }
        
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message,
        };
    }
};

const uploadSlideDeck = async (type, slideDeck, teamName, caseStudy) => {
    const slideDeckUrl = await uploadFile(type, slideDeck, teamName, caseStudy)

    const finaleSubmissionDocRef = doc(db, "finaleSubmission", teamName);
    const finaleSubmissionDocSnap = await getDoc(finaleSubmissionDocRef);

    if (finaleSubmissionDocSnap.exists()) {
        await updateDoc(finaleSubmissionDocRef, {
            slideDeck: slideDeckUrl,
        })
    }

    return slideDeckUrl
};

const uploadFile = (type, file, teamName, caseStudy) => {
    return new Promise((resolve, reject) => {
        const randomString = Math.random().toString(36).substring(2, 7);
        const storageRef = ref(storage, `${type}/${teamName}_${caseStudy}_${file.name}_${randomString}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', {
            error: (error) => {
                reject(new Error(error));
            },
            complete: () => {
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((downloadURL) => {
                        resolve(downloadURL);
                    })
                    .catch((error) => {
                        reject(new Error(error));
                    });
            },
        });
    });
};

const isProjectSubmitted = async () => {
    try {
        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
    
            if (userDocSnap.data().teamName) {
                const teamName = userDocSnap.data().teamName;
                const finaleSubmissionDocRef = doc(db, "finaleSubmission", teamName);
                const finaleSubmissionDocSnap = await getDoc(finaleSubmissionDocRef);
    
                if (finaleSubmissionDocSnap.exists()) {
                    return true;
                } else {
                    return false;
                }
            } else {
                throw new Error("Team profile not submitted.");
            }
        }
        
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message,
        };
    }
};

const getProjectDetails = async () => {
    try {
        const user = auth.currentUser;

        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
    
            if (userDocSnap.data().teamName) {
                const teamName = userDocSnap.data().teamName;
                const finaleSubmissionDocRef = doc(db, "finaleSubmission", teamName);
                const finaleSubmissionDocSnap = await getDoc(finaleSubmissionDocRef);
    
                if (finaleSubmissionDocSnap.exists()) {
                    return finaleSubmissionDocSnap.data();
                } else {
                    throw new Error("Project not submitted.");
                }
            } else {
                throw new Error("Team profile not submitted.");
            }
        }
        
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message,
        };
    }
};

export default {
    selectCaseStudy,
    getSelectedCaseStudy,
    getCaseStudyStatistics,
    submitPreliminaryProject,
    submitFinaleProject,
    isProjectSubmitted,
    getProjectDetails,
}