export default {
    
    SET_ASSIGNED_PROJECTS(state, payload) {
        state.assignedProjects = payload
    },

    SET_JUDGE_IDENTITY(state, payload) {
        state.judgeIdentity = payload
    },

    SET_PROJECTS_WITH_SUBMITTED_SCORES(state, payload) {
        state.projectsWithSubmittedScores = payload
    },

    SET_CURRENT_PROJECT_SCORE(state, payload) {
        state.currentProjectScore = payload
    },

}