import auth from "@/firebase/auth";
import router from "@/router";
import participant from "@/firebase/participant";

export default {

    async registerParticipantAccount({ dispatch }, data) {
        const response = await auth.registerParticipantAccount(data);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });

            router.push({ name: 'LoginParticipant' })
        }
    },

    async loginParticipant({ commit, dispatch }, data) {
        const response = await auth.loginUser(data);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });
            return

        } else {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });
            
            commit('SET_USER_DATA', response.user)

            router.push({ name: 'Home' })
            dispatch('isTeamProfileSubmitted')
        }
    },

    async getUser({ commit }) {
        const response = await auth.getUser();

        if (response) {
            commit('SET_USER_DATA', response)
        }
    },

    async isTeamProfileSubmitted() {
        const isTeamProfileSubmitted = await participant.isTeamProfileSubmitted()

        // if team profile is not submitted, redirect to create team profile page
        if (!isTeamProfileSubmitted) {
            if (router.currentRoute.name !== 'CreateTeamProfile') {
                router.push({ name: 'CreateTeamProfile' });
            }
        }
    },

    async logoutUser({ commit, dispatch }) {
        const response = await auth.logoutUser();

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });

            commit('SET_USER_DATA', null)
            
            if (router.currentRoute.name !== 'Home') {
                router.push({ name: 'Home' })
            }
        }
    },

    async sendResetPasswordEmail({ dispatch }, email) {
        const response = await auth.sendResetPasswordEmail(email);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });
        }

        router.push({ name: 'LoginParticipant' })

    },

    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Judge
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    async loginJudge({ dispatch }, data) {
        const response = await auth.sendLoginLinkToJudge(data);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });
            return

        } else {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });
            
            router.push({ name: 'JudgeDashboard' })
        }
    },

    async verifyJudge() {
        await auth.verifyJudge();
    }

}