import submission from '@/firebase/submission';

export default {

    async selectCaseStudy({ dispatch }, { caseStudy }) {
        const response = await submission.selectCaseStudy(caseStudy);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {

            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });

            dispatch('getSelectedCaseStudy');
        }
    },

    async getSelectedCaseStudy({ commit, dispatch }) {
        const response = await submission.getSelectedCaseStudy();

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_SELECTED_CASE_STUDY', response);
        }
    },

    async getCaseStudyStatistics({ commit, dispatch }) {
        const response = await submission.getCaseStudyStatistics();

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_CASE_STUDY_STATISTICS', response);
        }
    },

    async submitPreliminaryProject({ dispatch }, { project }) {
        const response = await submission.submitPreliminaryProject(project);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {

            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });

            dispatch('isProjectSubmitted');
            dispatch('getProjectDetails');
        }
    },

    async submitFinaleProject({ dispatch }, { project }) {
        const response = await submission.submitFinaleProject(project);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {

            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });

            dispatch('isProjectSubmitted');
            dispatch('getProjectDetails');
        }
    },

    async isProjectSubmitted({ commit, dispatch }) {
        const response = await submission.isProjectSubmitted();

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_PROJECT_SUBMITTED', response);
        }
    },

    async getProjectDetails({ commit, dispatch }) {
        const response = await submission.getProjectDetails();

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_PROJECT_DETAILS', response);
        }
    },

}