import helper from '@/utils/helper';

export default {

    getSelectedCaseStudy(state) {
        return state.selectedCaseStudy;
    },

    getCaseStudyStatistics(state) {
        if (state.caseStudyStatistics) return Object.values(state.caseStudyStatistics);
    },

    isProjectSubmitted(state) {
        return state.isProjectSubmitted;
    },

    getProjectDetails(state) {
        if (state.projectDetails.prototypeDemoVideoUrl) {
            const url = state.projectDetails.prototypeDemoVideoUrl;
            state.projectDetails.prototypeDemoVideoUrl = helper.getEmbedUrl(url);

        }

        return state.projectDetails;
    },
}