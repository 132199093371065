import { doc, getDoc, setDoc, updateDoc, getDocs, collection } from "firebase/firestore";
import { auth, db } from "@/firebase/config";
import gsheet from "@/utils/gsheet";

const getJudgeIdentity = async (email) => {
    try {
        const docRef = doc(db, "judgesList", email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return {
                judge: docSnap.data()
            }
        }

        return false;
        
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const getAssignedProjects = async () => {
    try {
        const user = auth.currentUser;
        const judgeDocRef = doc(db, "judgesList", user.email);
        const judgeDocSnap = await getDoc(judgeDocRef);

        if (!judgeDocSnap.exists()) {
            return []
        }

        const assignedProjects = judgeDocSnap.data().projects;

        if (!assignedProjects) {
            return [];
        }
        // for each project, get the project details from preliminarySubmission collection
        const assignedProjectsDetails = [];

        for (const project of assignedProjects) {
            const projectDocRef = doc(db, "preliminarySubmission", project);
            const projectDocSnap = await getDoc(projectDocRef);

            if (!projectDocSnap.exists()) {
                continue;
            }

            assignedProjectsDetails.push({
                teamName: project,
                ...projectDocSnap.data()
            });
        }

        return assignedProjectsDetails;
    
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const submitJudgingScore = async (projectName, teamName, judgeFeedback, score, judgeEmail) => {
    try {
        const user = auth.currentUser;
        const scoreDocId = `${teamName}_${judgeEmail}`;
        const scoresDocRef = doc(db, "judgingScores", scoreDocId);
        const scoresDocSnap = await getDoc(scoresDocRef);

        if (scoresDocSnap.exists()) {
            await updateDoc(scoresDocRef, {
                judgeFeedback,
                ...score
            });
        }

        await setDoc(scoresDocRef, {
            judge: user.email,
            createdAt: new Date(),
            teamName,
            projectName,
            judgeFeedback,
            ...score
        });

        const gsheetData = {
            sheet: "raw_judge_scores",
            judge_email: user.email,
            team_name: teamName,
            project_name: projectName,
            judge_feedback: judgeFeedback,
            outreach_initiative_5: (score.advocacy.criteria.outreachInitiative.inputScore / 10) * 5,
            public_policy_coverage_15: (score.advocacy.criteria.publicPolicyCoverage.inputScore / 10) * 15,
            presentation_15: (score.advocacy.criteria.presentation.inputScore / 10) * 15,
            problem_statement_10: (score.solution.criteria.problemStatement.inputScore / 10) * 10,
            objective_impact_10: (score.solution.criteria.objectiveImpact.inputScore / 10) * 10,
            functionality_15: (score.solution.criteria.functionality.inputScore / 10) * 15,
            prototype_15: (score.solution.criteria.prototype.inputScore / 10) * 15,
            innovativeness_5: (score.solution.criteria.innovativeness.inputScore / 10) * 5,
            solution_development_plan_10: (score.solution.criteria.solutionDevelopmentPlan.inputScore / 10) * 10,
        };
        await gsheet.sendDataToGoogleSheet(gsheetData);

        return {
            message: `Score for ${projectName} submitted successfully.`
        };

    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const isScoreSubmitted = async (teamName, judgeEmail) => {
    try {
        const scoreDocId = `${teamName}_${judgeEmail}`;
        const scoresDocRef = doc(db, "judgingScores", scoreDocId);
        const scoresDocSnap = await getDoc(scoresDocRef);

        return scoresDocSnap.exists()
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const getProjectsWithSubmittedScores = async (judgeEmail) => {
    try {
        const projectsWithSubmittedScores = [];

        const scoresCollectionRef = await getDocs(collection(db, "judgingScores"));
        scoresCollectionRef.forEach((doc) => {
            if (doc.id.includes(judgeEmail)) {
                projectsWithSubmittedScores.push(doc.id);
            }
        });

        return projectsWithSubmittedScores;
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const getCurrentProjectScore = async (teamName, judgeEmail) => {
    try {
        const scoreDocId = `${teamName}_${judgeEmail}`;
        const scoresDocRef = doc(db, "judgingScores", scoreDocId);
        const scoresDocSnap = await getDoc(scoresDocRef);

        return scoresDocSnap.data();

    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

export default {
    getJudgeIdentity,
    getAssignedProjects,
    submitJudgingScore,
    isScoreSubmitted,
    getProjectsWithSubmittedScores,
    getCurrentProjectScore,
}