export default {

    getJudgeIdentity(state) {
        return state.judgeIdentity
    },

    getAssignedProjects(state) {
        return state.assignedProjects
    },

    isProjectScoreSubmitted: (state) => (teamName, judgeEmail) => {
        const docId = `${teamName}_${judgeEmail}`
        return state.projectsWithSubmittedScores.includes(docId)
    },

    getCurrentProjectScore(state) {
        return state.currentProjectScore
    },

}