<template>
  <v-app>
    <NavBar v-if="showNavbar"/>
    <v-main style="margin-top: 80px;">
      <router-view />
      <SnackbarNotification />
    </v-main>
    <FooterBar/>
  </v-app>
</template>

<script>
import NavBar from '@/components/global/NavBar.vue';
import FooterBar from '@/components/global/FooterBar.vue';
import SnackbarNotification from '@/components/global/SnackbarNotification.vue';

export default {
  name: 'App',

  components: {
    NavBar,
    FooterBar,
    SnackbarNotification,
  },

  data: () => ({
    //
  }),

  computed: {
    showNavbar() {
      const navbarlessPaths = [
        '/login',
        '/register',
        '/judging/login',
        '/judging/verify',
      ]
      
      return !navbarlessPaths.includes(this.$route.path)
    }
  },

  async mounted() {
    await this.$store.dispatch('auth/getUser')
  },

};
</script>

<style lang="scss" >
@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$body-font-family: 'Commissioner';
$title-font: 'Commissioner';

html,
body {
  font-family: 'Commissioner', sans-serif !important;
}

.v-application .v-application--wrap * {
  font-family: $body-font-family !important;
}

#app {
  font-family: 'Commissioner', sans-serif !important;
  background: rgb(156, 156, 156);
  background-image:
    radial-gradient(at 79% 100%, hsla(36,100%,78%,1) 0px, transparent 50%),
    radial-gradient(at 100% 25%, hsla(193,98%,57%,1) 0px, transparent 50%),
    radial-gradient(at 21% 0%, hsla(222,96%,58%,1) 0px, transparent 50%),
    radial-gradient(at 0% 74%, hsla(0,100%,78%,1) 0px, transparent 50%);
  word-wrap: break-word;
}

</style>

