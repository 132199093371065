export default {

    async sendDataToGoogleSheet (data) {
        const paramsObject = {
            ...data,
        };
        const params = new URLSearchParams(paramsObject);
    
        const response = await fetch(process.env.VUE_APP_GOOGLE_SHEETS_URL, {
            method: "POST",
            body: params,
        });
    
        if (!response.ok) {
            console.log(response);
        }
    },
}