export default {

    SET_SELECTED_CASE_STUDY(state, payload) {
        state.selectedCaseStudy = payload;
    },

    SET_CASE_STUDY_STATISTICS(state, payload) {
        state.caseStudyStatistics = payload;
    },

    SET_PROJECT_SUBMITTED(state, payload) {
        state.isProjectSubmitted = payload;
    },

    SET_PROJECT_DETAILS(state, payload) {
        state.projectDetails = payload;
    },
}