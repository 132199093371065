import { auth, db } from "@/firebase/config";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithEmailAndPassword, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signOut, sendPasswordResetEmail } from "firebase/auth";
import gsheet from "@/utils/gsheet";
import router from "@/router";
import judge from "@/firebase/judge";

const firebaseAuthErrorCodes = {
    "auth/email-already-in-use": "The email address is already in use by another account.",
    "auth/invalid-email": "The email address is badly formatted.",
    "auth/operation-not-allowed": "This operation is not allowed.",
    "auth/weak-password": "The password must be 6 characters long or more.",
    "auth/user-disabled": "This account has been disabled.",
    "auth/user-not-found": "Email or password is incorrect.",
    "auth/wrong-password": "Email or password is incorrect."
}

const registerParticipantAccount = async (data) => {
    try {
        if (data.email === "") {
            throw new Error('Please enter your email.');
        }

        if (!validateEmail(data.email)) {
            throw new Error('Please enter a valid email.');
        }

        if (data.password === "") {
            throw new Error('Please enter your password.');
        }

        if (data.password !== data.confirmPassword) {
            throw new Error('Passwords do not match.');
        }

        const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password)

        const user = userCredential.user;
        await sendEmailVerification(auth.currentUser)

        const docRef = doc(db, "users", user.uid);
        await setDoc(docRef, {
            email: data.email,
            role: "participant",
            createdAt: new Date(),
            updatedAt: new Date(),
        });

        const gsheetData = {
            sheet: "register_account",
            email: data.email
        };
        await gsheet.sendDataToGoogleSheet(gsheetData);

        await logoutUser();
        return {
            user: user,
            message: "Sign up successful. Please check your email to verify your account. (Remember to check your spam folder too!)"
        };
    
    } catch (e) {
        return {
            error: true,
            errorMessage: firebaseAuthErrorCodes[e.code] || e.message
        };
    }
}

const loginUser = async (data) => {
    try {
        if (data.email === "") {
            throw new Error('Please enter your email.');
        }

        if (!validateEmail(data.email)) {
            throw new Error('Please enter a valid email.');
        }

        if (data.password === "") {
            throw new Error('Please enter your password.');
        }

        const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password)
        const user = userCredential.user;

        if (!user.emailVerified) {
            await logoutUser();
            throw new Error('Please verify your account first. Check your email for the verification link. (Remember to check your spam folder too!)');
        }

        return {
            user: user,
            message: "Login successful!"
        };

    } catch (e) {
        return {
            error: true,
            errorMessage: firebaseAuthErrorCodes[e.code] || e.message
        };
    }
}

const getUser = async () => {
    try {
        const user = auth.currentUser;

        if (user) {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                return {
                    user: user,
                    role: docSnap.data().role
                }
            } else {
                throw new Error('User not found.');
            }
        }

    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const logoutUser = async () => {
    try {
        await signOut(auth)

        return {
            message: "Logout successful!"
        }

    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const sendResetPasswordEmail = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email)
       
        return {
            message: "Password reset email sent. (Remember to check your spam folder too!)"
        }

    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}
    
const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
// Judge
////////////////////////////////////////////////////////////////////////////////////////////////////////

const sendLoginLinkToJudge = async (data) => {
    try {
        const judgeDetails = await judge.getJudgeIdentity(data.email)

        if (judgeDetails) {
            const actionCodeSettings = {
                url: process.env.VUE_APP_BASE_URL + "/judging/verify",
                handleCodeInApp: true,
            };
    
            await sendSignInLinkToEmail(auth, data.email, actionCodeSettings);
    
            window.localStorage.setItem("emailForJudgeLogin", data.email);
    
            return {
                message: "Please check your email for the login link. (Remember to check your spam folder too!)"
            };

        } else {
            throw new Error('An error occurred. Please contact itank.usm@gmail.com for further assistance.');
        }

    } catch (e) {
        return {
            error: true,
            errorMessage: firebaseAuthErrorCodes[e.code] || e.message
        };
    }
}

const verifyJudge = async () => {
    try {
        if (isSignInWithEmailLink(auth, window.location.href)) {

            let email = window.localStorage.getItem('emailForJudgeLogin');
            if (!email) {
                email = window.prompt('Please provide your email for confirmation');
            }

            await signInWithEmailLink(auth, email, window.location.href)

            window.localStorage.removeItem('emailForSignIn');
            
            setTimeout(() => {
                router.push({ name: 'JudgeDashboard' })
            }, 5000)
        }
    } catch (e) {
        return {
            error: true,
            errorMessage: firebaseAuthErrorCodes[e.code] || e.message
        };
    }
}

export default {
    registerParticipantAccount,
    loginUser,
    getUser,
    validateEmail,
    logoutUser,
    sendLoginLinkToJudge,
    verifyJudge,
    sendResetPasswordEmail,
}