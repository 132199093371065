import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from '@/store/index'
import { auth } from '@/firebase/config'
import { onAuthStateChanged } from "firebase/auth";
import PrimeVue from 'primevue/config';
import Chart from 'primevue/dialog';

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(PrimeVue);
Vue.component('ChartPrimevue', Chart);

// With the new navigation failures, replicate vue-router-next behavior
// Implemented to prevent error in console when navigation to same route
const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};


onAuthStateChanged(auth, () => {
  new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
  }).$mount('#app')
});

