import Vue from "vue";
import Vuex from "vuex";
import global from "@/store/global/index";
import auth from "@/store/auth/index";
import participant from "@/store/participant/index";
import submission from "@/store/submission/index";
import judge from "@/store/judge/index";

Vue.config.devtools = true
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    auth,
    participant,
    submission,
    judge,
  }
});

