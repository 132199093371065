import participant from "@/firebase/participant";
import router from "@/router";

export default {

    async createTeamProfile({ dispatch }, { formData }) {
        const response = await new Promise(resolve => {
            const result = participant.createTeamProfile(formData);
            resolve(result);
        });

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {

            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });
            
            router.push({ name: 'TeamDashboard' })
        }
    },

    async getTeamProfile({ commit, dispatch }) {
        const response = await participant.getTeamProfile();

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_TEAM_PROFILE', response);
        }
    },

    async uploadStudentEndorsementLetter({ dispatch }, { studentEndorsementLetter, member, teamName }) {
        const response = await participant.uploadStudentEndorsementLetter(studentEndorsementLetter, member, teamName);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });

            await dispatch('getTeamProfile');
        }
    },

    async updateTeamProfile({ dispatch }, { teamMembers, teamName }) {
        const response = await new Promise(resolve => {
            const result = participant.updateTeamProfile(teamMembers, teamName);
            resolve(result);
        });

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {

            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });
            
            router.push({ name: 'TeamDashboard' })
        }
    },

    async addNewTeamMember({ dispatch }, { newTeamMember, teamName }) {
        const response = await participant.addNewTeamMember(newTeamMember, teamName);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });

            await dispatch('getTeamProfile');
        }
    },

    async isFinale({ commit, dispatch }, email) {
        const response = await participant.isFinale(email);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_IS_FINALE', response);
        }
    }
}