import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "@/firebase/config";
import { storage } from "@/firebase/config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import gsheet from "@/utils/gsheet";

const createTeamProfile = async (formData) => {
    try {
        if (!validateTeamName(formData.teamName)) {
            throw new Error('Team name is already taken.');
        }

        if (formData.teamName === "") {
            throw new Error('Please fill in all fields.');
        }

        for (const key in formData.teamMembers) {
            if (formData[key] === "" || formData[key] === null) {
                throw new Error('Please fill in all fields.');
            }
        }

        const user = auth.currentUser;
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
            teamName: formData.teamName,
        });        

        for (const member of formData.teamMembers) {
            // Remove the '+' sign from the contact number so that it can be stored in Google Sheet without any issues
            member.fullContactNumber = member.fullContactNumber.replace(/\+/g, "")
            if (member.studentEndorsementLetter) {
                const response = await uploadStudentEndorsementLetter(member.studentEndorsementLetter, member, formData.teamName)
                member.studentEndorsementLetter = response.studentEndorsementLetterFileUrl
            }
        }

        const teamDocRef = doc(db, "teamProfiles", formData.teamName);
        const teamDocSnap = await getDoc(teamDocRef);
        if (!teamDocSnap.exists()) {
            await setDoc(teamDocRef, {
                ...formData,
                leaderUID: user.uid,
                createdAt: new Date(),
                updatedAt: new Date(),
            });
        }

        for (const member of formData.teamMembers) {
            const data = {
              sheet: "team_profile",
              team_name: teamDocSnap.id,
              entry_type: 'create',
              ...member,
            };
            await gsheet.sendDataToGoogleSheet(data);
        }

        return {
            message: "Team profile submitted successfully."
        };
    
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const updateTeamProfile = async (teamMembers, teamName) => {
    try {
        for (const member of teamMembers) {
            member.fullContactNumber = member.fullContactNumber.replace(/\+/g, "")
            if (typeof member.studentEndorsementLetter === 'object' && member.studentEndorsementLetter !== null) {
                const response = await uploadStudentEndorsementLetter(member.studentEndorsementLetter, member, teamName)
                member.studentEndorsementLetter = response.studentEndorsementLetterFileUrl
            }
        }

        const teamDocRef = doc(db, "teamProfiles", teamName);
        const teamDocSnap = await getDoc(teamDocRef);
        if (teamDocSnap.exists()) {
            await updateDoc(teamDocRef, {
                teamMembers: [...teamMembers],
                updatedAt: new Date(),
            });
        }

        for (const member of teamMembers) {
            const data = {
              sheet: "team_profile",
              team_name: teamName,
              entry_type: 'update',
              ...member,
            };
            await gsheet.sendDataToGoogleSheet(data);
        }

        return {
            message: "Team profile updated successfully."
        };
        
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const addNewTeamMember = async (newTeamMember, teamName) => {
    try {
        const teamDocRef = doc(db, "teamProfiles", teamName);
        const teamDocSnap = await getDoc(teamDocRef);

        if (teamDocSnap.exists()) {
            newTeamMember.fullContactNumber = newTeamMember.fullContactNumber.replace(/\+/g, "")
            if (newTeamMember.studentEndorsementLetter) {
                const response = await uploadStudentEndorsementLetter(newTeamMember.studentEndorsementLetter, newTeamMember, teamName, true)
                newTeamMember.studentEndorsementLetter = response.studentEndorsementLetterFileUrl
            }

            const data = {
                sheet: "team_profile",
                team_name: teamName,
                entry_type: 'add_new_member',
                ...newTeamMember,
            };
            await gsheet.sendDataToGoogleSheet(data);

            await updateDoc(teamDocRef, {
                teamMembers: [...teamDocSnap.data().teamMembers, newTeamMember],
                updatedAt: new Date(),
            });
        }

        return {
            message: "Team member added successfully."
        };

    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

const getTeamProfile = async () => {
    const user = auth.currentUser;

    if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        // check if user has created a team profile
        if (userDocSnap.data().teamName) {
            const teamName = userDocSnap.data().teamName;
    
            const teamDocRef = doc(db, "teamProfiles", teamName);
            const teamDocSnap = await getDoc(teamDocRef);
            return {
                teamName,
                ...teamDocSnap.data(),
            };
        }
    }

    return false
}

const isTeamProfileSubmitted = async () => {
    const user = auth.currentUser;
    if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if  (userDocSnap.data().teamName) {
            return true;
        }
    }

    return false;
}

const validateTeamName = async (teamName) => {
    const docRef = doc(db, "teamProfiles", teamName);
    const docSnap = await getDoc(docRef);

    const user = auth.currentUser;

    if (user) {
        if (docSnap.exists()) { // if team profile exists
            return docSnap.data().leaderUID === user.uid; // return true if the team profile belongs to the user
        } else {
            return true; // return true if the team profile does not exist
        }
    }
}

const uploadStudentEndorsementLetter = async (studentEndorsementLetter, member, teamName, addNewMemberToExistingTeam = false) => {
    const studentEndorsementLetterFileUrl = await uploadFile(studentEndorsementLetter, teamName, member.name)

    // for existing team: find the member in the team profile and update the student_endorsement_letter field
    const teamDocRef = doc(db, "teamProfiles", teamName);
    const teamDocSnap = await getDoc(teamDocRef);
    if (!addNewMemberToExistingTeam && teamDocSnap.exists()) {
        const teamProfile = teamDocSnap.data();
        const teamMembers = teamProfile.teamMembers;
        const memberIndex = teamMembers.findIndex((teamMember) => teamMember.name === member.name);

        teamMembers[memberIndex].studentEndorsementLetter = studentEndorsementLetterFileUrl;
        await updateDoc(teamDocRef, {
            teamMembers,
            updatedAt: new Date(),
        });
    }

    await gsheet.sendDataToGoogleSheet({
        sheet: "endorsement_letter",
        student_endorsement_letter: studentEndorsementLetterFileUrl,
        team_name: teamName,
        contact: member.fullContactNumber,
        ...member
    })

    return {
        studentEndorsementLetterFileUrl,
        message: "Student endorsement letter uploaded successfully."
    }
}

const uploadFile = (file, teamName, memberName) => {
    return new Promise((resolve, reject) => {
        const randomString = Math.random().toString(36).substring(2, 7);
        const storageRef = ref(storage, `student_identity_verification/${teamName}_${memberName}_${file.name}_${randomString}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed', {
            error: (error) => {
                reject(new Error(error));
            },
            complete: () => {
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((downloadURL) => {
                        resolve(downloadURL);
                    })
                    .catch((error) => {
                        reject(new Error(error));
                    });
            },
        });
    });
};

const isFinale = async (email) => {
    try {
        const docRef = doc(db, "finaleList", email);
        const docSnap = await getDoc(docRef);

        return docSnap.exists();
        
    } catch (e) {
        return {
            error: true,
            errorMessage: e.message
        };
    }
}

export default {
    createTeamProfile,
    getTeamProfile,
    isTeamProfileSubmitted,
    validateTeamName,
    uploadStudentEndorsementLetter,
    updateTeamProfile,
    addNewTeamMember,
    isFinale,
}