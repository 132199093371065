import judge from "@/firebase/judge";

export default {

    async getJudgeIdentity({ commit, dispatch }, email) {
        const response = await judge.getJudgeIdentity(email);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_JUDGE_IDENTITY', response)
        }
    },

    async getAssignedProjects({ commit, dispatch }) {
        const response = await judge.getAssignedProjects();

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_ASSIGNED_PROJECTS', response)
        }
    },

    async submitJudgingScore({ dispatch }, { projectName, teamName, judgeFeedback, score, judgeEmail }) {
        const response = await judge.submitJudgingScore(projectName, teamName, judgeFeedback, score, judgeEmail);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.message,
                color: "success"
            }, { root: true });

            dispatch('getProjectsWithSubmittedScores', judgeEmail);
        }
    },

    async getProjectsWithSubmittedScores({ commit, dispatch }, judgeEmail) {
        const response = await judge.getProjectsWithSubmittedScores(judgeEmail);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_PROJECTS_WITH_SUBMITTED_SCORES', response)
        }
    },

    async getCurrentProjectScore({ commit, dispatch }, { teamName, judgeEmail }) {
        const response = await judge.getCurrentProjectScore(teamName, judgeEmail);

        if (response.error) {
            dispatch('global/triggerGlobalSnackbar', {
                show: true,
                text: response.errorMessage,
                color: "error"
            }, { root: true });

        } else {
            commit('SET_CURRENT_PROJECT_SCORE', response)
        }
    },

}